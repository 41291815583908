var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"DataTable"},[_c('div',[(_vm.gridMobile && _vm.isMobile)?_c('div',{staticClass:"mobile"},[_c('q-card',{staticClass:"card-header"},[_c('q-card-section',{staticClass:"q-mx-lg"},[(_vm.filterData)?_c('q-input',{staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Filtro","clearable":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{attrs:{"name":"search"}})]},proxy:true}],null,false,4009527860),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e(),(_vm.title)?_c('span',{staticClass:"text h6"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_c('q-card-section',[(_vm.actionButtonLabel)?_c('q-btn',{staticClass:"on-right",attrs:{"label":_vm.actionButtonLabel,"color":_vm.actionButtonColor,"icon-right":_vm.actionButtonIcon,"flat":"","outline":""},on:{"click":_vm.actionButtonClick}}):_vm._e()],1)],1),_vm._l((_vm.data),function(row,index){return _c('q-card',{key:index,staticClass:"q-mt-md",nativeOn:{"click":function($event){return _vm.rowClick(row)}}},[_c('q-card-section',_vm._l((_vm.columns),function(column,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col text-bold"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[(column.badge)?_c('q-badge',{attrs:{"color":column.badge.find(item => item.value === row[column.field])
                    ? column.badge.find(
                        item => item.value === row[column.field]
                      ).color
                    : 'grey'}},[_vm._v(_vm._s(column.badge.find(item => item.value === row[column.field]) ? column.badge.find( item => item.value === row[column.field] ).text : ""))]):_c('span',[_vm._v(_vm._s(row[column.field]))])],1)])}),0)],1)})],2):_c('q-table',{staticClass:"my-sticky-header-table",attrs:{"data":_vm.data,"columns":_vm.columns,"hide-pagination":_vm.hidePagination,"pagination":_vm.initialPagination,"dense":_vm.dense},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"col-8"},[(_vm.filterData)?_c('q-input',{staticStyle:{"width":"100%"},attrs:{"type":"text","label":"Buscar","clearable":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{attrs:{"name":"search"}})]},proxy:true}],null,false,4009527860),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e(),(_vm.title)?_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()],1),_c('div',{staticClass:"col-auto"},[(_vm.actionButtonLabel)?_c('q-btn',{attrs:{"label":_vm.actionButtonLabel,"color":_vm.actionButtonColor,"icon-right":_vm.actionButtonIcon,"flat":"","outline":""},on:{"click":_vm.actionButtonClick}}):_vm._e()],1)])]},proxy:true},{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props},nativeOn:{"click":function($event){return _vm.rowClick(props.row)}}},_vm._l((props.cols),function(column){return _c('q-td',{key:column.field},[(column.badge)?_c('q-badge',{attrs:{"color":column.badge.find(
                  item => item.value === props.row[column.field]
                )
                  ? column.badge.find(
                      item => item.value === props.row[column.field]
                    ).color
                  : 'grey'}},[_vm._v(_vm._s(column.badge.find( item => item.value === props.row[column.field] ) ? column.badge.find( item => item.value === props.row[column.field] ).text : ""))]):(column.type || column.type === 'string')?_c('span',[_vm._v(_vm._s(_vm.formatString( props.row[column.field] || "", column.type, null, _vm.string )))]):(column.type || column.type === 'date')?_c('span',[_vm._v(_vm._s(_vm.formatString( props.row[column.field] || "", column.type, null, _vm.currency )))]):(column.type || column.type !== 'date')?_c('span',[_vm._v(_vm._s(_vm.formatString( props.row[column.field] || 0, column.type, null, _vm.currency ))+" ")]):_c('span',[_vm._v(_vm._s(props.row[column.field]))])],1)}),1)]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }